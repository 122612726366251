exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-first-cover-activate-tsx": () => import("./../../../src/pages/first-cover-activate.tsx" /* webpackChunkName: "component---src-pages-first-cover-activate-tsx" */),
  "component---src-pages-first-cover-tsx": () => import("./../../../src/pages/first-cover.tsx" /* webpackChunkName: "component---src-pages-first-cover-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nels-first-cover-tsx": () => import("./../../../src/pages/nels-first-cover.tsx" /* webpackChunkName: "component---src-pages-nels-first-cover-tsx" */),
  "component---src-pages-nels-motor-insurance-tsx": () => import("./../../../src/pages/nels-motor-insurance.tsx" /* webpackChunkName: "component---src-pages-nels-motor-insurance-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

