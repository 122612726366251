/*eslint no-unused-expressions: "off"*/
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

if (CONFIG.twilio) {
  window.Twilio?.initLogger('info')
  window.Twilio?.initWebchat(CONFIG.twilio);
}

